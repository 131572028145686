import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroll-component'

import { yupResolver } from '@hookform/resolvers/yup'
import cn from 'classnames'
import * as yup from 'yup'

import { getBondedTokens, getPrebondTokens } from '@/api/tokens'
import { TVirtualToken } from '@/api/tokens/types'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonGroupRadio, ButtonGroupRadioButton, Typography } from '@/libs/common'
import { LocalStorageItem } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { QuickBuy } from './libs/quick-buy'
import { Section } from './libs/section'
import { TokenCard } from './libs/token-card'
import styles from './styles.module.scss'
import { LoadingState, QuickBuyFormValues, SectionType } from './types'

// const chains = [
// {
//   value: 'ETH',
//   label: 'ETH',
//   image: IconName.ETHEREUM,
// },
// {
//   value: 'BSC',
//   label: 'BSC',
//   image: IconName.BNB_V2,
// },
// {
//   value: 'BASE',
//   label: 'BASE',
//   image: IconName.BASE_NETWORK,
// },
// {
//   value: 'SOL',
//   label: 'SOL',
//   image: IconName.SOLANA,
// },
// ]

const BREAKPOINT = 1200

const PumpVision = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [loadingState, setLoadingState] = useState<LoadingState>({
    bonded: false,
    new: false,
    aboutToBond: false,
  })
  const [bondedTokens, setBondedTokens] = useState<TVirtualToken[]>([])
  const [prebondCreatedAtTokens, setPrebondCreatedAtTokens] = useState<TVirtualToken[]>([])
  const [prebondMcapTokens, setPrebondMcapTokens] = useState<TVirtualToken[]>([])
  const [isQuickBuyEdit, setIsQuickBuyEdit] = useState(false)
  const pageRefs = useRef({
    bonded: 1,
    new: 1,
    aboutToBond: 1,
  })
  const isLoadingRef = useRef(false)
  const [hasMore, setHasMore] = useState({
    bonded: true,
    new: true,
    aboutToBond: true,
  })
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const { width: windowWidth } = useWindowDimensions()

  const quickBuySchema = yup.object({
    amount: yup
      .string()
      .required('Amount is required')
      .test(
        'max-balance',
        'Insufficient balance',
        (value) => !value || +value <= +(mainWallet?.balanceFormatted || 0),
      ),
  })

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm<QuickBuyFormValues>({
    defaultValues: {
      amount: localStorage.getItem(LocalStorageItem.QUICK_BUY_AMOUNT) ?? '0.0',
    },
    resolver: yupResolver(quickBuySchema),
  })

  const quickBuyAmount = watch('amount')

  const fetchTokens = async (pageNum: number, section?: SectionType) => {
    try {
      if (isLoadingRef.current) return

      if (!section) {
        setLoadingState({
          bonded: true,
          new: true,
          aboutToBond: true,
        })
      } else {
        setLoadingState((prev) => ({
          ...prev,
          [section]: true,
        }))
      }

      isLoadingRef.current = true

      if (!section) {
        const [graduatedResponse, prebondCreatedAtResponse, prebondMcapResponse] =
          await Promise.all([
            getBondedTokens('en', pageNum),
            getPrebondTokens('en', pageNum, 'created_at'),
            getPrebondTokens('en', pageNum, 'mcap'),
          ])

        if (pageNum === 1) {
          setBondedTokens(graduatedResponse.data.data.tokens)
          setPrebondCreatedAtTokens(prebondCreatedAtResponse.data.data.tokens)
          setPrebondMcapTokens(prebondMcapResponse.data.data.tokens)
        } else {
          setBondedTokens((prev) => [...prev, ...graduatedResponse.data.data.tokens])
          setPrebondCreatedAtTokens((prev) => [
            ...prev,
            ...prebondCreatedAtResponse.data.data.tokens,
          ])
          setPrebondMcapTokens((prev) => [...prev, ...prebondMcapResponse.data.data.tokens])
        }

        const noMoreTokens = {
          bonded: !graduatedResponse.data.data.tokens.length,
          new: !prebondCreatedAtResponse.data.data.tokens.length,
          aboutToBond: !prebondMcapResponse.data.data.tokens.length,
        }

        setHasMore((prev) => ({
          ...prev,
          bonded: !noMoreTokens.bonded,
          new: !noMoreTokens.new,
          aboutToBond: !noMoreTokens.aboutToBond,
        }))
      } else {
        const sectionConfig = {
          bonded: {
            fetchFn: () => getBondedTokens('en', pageNum),
            setter: setBondedTokens,
          },
          new: {
            fetchFn: () => getPrebondTokens('en', pageNum, 'created_at'),
            setter: setPrebondCreatedAtTokens,
          },
          aboutToBond: {
            fetchFn: () => getPrebondTokens('en', pageNum, 'mcap'),
            setter: setPrebondMcapTokens,
          },
        }

        const config = sectionConfig[section]
        const response:
          | Awaited<ReturnType<typeof getBondedTokens>>
          | Awaited<ReturnType<typeof getPrebondTokens>> = await config.fetchFn()
        const tokens = response.data.data.tokens

        config.setter((prev) => (pageNum === 1 ? tokens : [...prev, ...tokens]))

        setHasMore((prev) => ({
          ...prev,
          [section]: !!tokens.length,
        }))
      }
    } catch (error) {
      console.error('Error fetching tokens:', error)
      setHasMore((prev) => ({
        ...prev,
        [section || 'new']: false,
      }))
    } finally {
      if (!section) {
        setLoadingState({
          bonded: false,
          new: false,
          aboutToBond: false,
        })
      } else {
        setLoadingState((prev) => ({
          ...prev,
          [section]: false,
        }))
      }
      isLoadingRef.current = false
    }
  }

  useEffect(() => {
    pageRefs.current = {
      bonded: 1,
      new: 1,
      aboutToBond: 1,
    }
    fetchTokens(1)
  }, [])

  const handleQuickBuySubmit = handleSubmit((data: QuickBuyFormValues) => {
    const newAmount = data.amount
    setValue('amount', newAmount)
    localStorage.setItem(LocalStorageItem.QUICK_BUY_AMOUNT, newAmount)
    setIsQuickBuyEdit(false)
  })

  const handleLoadMore = (section: SectionType) => {
    if (!isLoadingRef.current && hasMore[section]) {
      const nextPage = pageRefs.current[section] + 1
      pageRefs.current[section] = nextPage
      fetchTokens(nextPage, section)
    }
  }

  const renderTokenGrid = (tokens: TVirtualToken[], section: SectionType) => {
    return (
      <div
        id={`token-grid-${section}`}
        className={styles.cardsGrid}
        style={{ height: '100%', overflowY: 'auto' }}
      >
        <InfiniteScroll
          dataLength={tokens?.length || 0}
          next={() => handleLoadMore(section)}
          hasMore={hasMore[section]}
          scrollableTarget={`token-grid-${section}`}
          // scrollThreshold={0.6}
          style={{ overflow: 'visible' }}
          endMessage={
            <Typography variant="body1" align="center">
              No more tokens to load
            </Typography>
          }
          loader={null}
        >
          {tokens?.map((token) => (
            <div key={`${token.token}-${section}`} className={styles.tokenCard}>
              <TokenCard
                quickBuyAmount={quickBuyAmount}
                section={section}
                key={token.token}
                token={token}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    )
  }

  const sections = [
    {
      value: 'new',
      label: 'Newly created',
      content: (tokens: TVirtualToken[], section: SectionType) => renderTokenGrid(tokens, section),
      data: prebondCreatedAtTokens,
    },
    {
      value: 'aboutToBond',
      label: 'About to Bond',
      content: (tokens: TVirtualToken[], section: SectionType) => renderTokenGrid(tokens, section),
      data: prebondMcapTokens,
    },
    {
      value: 'bonded',
      label: 'Bonded',
      content: (tokens: TVirtualToken[], section: SectionType) => renderTokenGrid(tokens, section),
      data: bondedTokens,
    },
  ]

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.infoText}>
          <Typography variant="body2" textColor="color-grey-3">
            For the virtual pre-bonded tokens, the chart is coming soon. You can manage your
            positions in &quot;Positions&quot; section of the Dashboard.
          </Typography>
        </div>
        <QuickBuy
          control={control}
          errors={errors}
          setValue={setValue}
          handleSubmit={handleQuickBuySubmit}
          isEdit={isQuickBuyEdit}
          setIsEdit={setIsQuickBuyEdit}
          watch={watch}
          reset={reset}
        />
      </div>
      <div className={styles.sections}>
        {/* Desktop view */}
        {windowWidth > BREAKPOINT ? (
          <div className={styles.desktopSections}>
            {sections.map((section) => {
              return (
                <Section
                  key={section.value}
                  refreshList={() => {
                    pageRefs.current[section.value as SectionType] = 1
                    fetchTokens(1, section.value as SectionType)
                  }}
                  isLoading={loadingState[section.value as SectionType]}
                  title={section.label}
                >
                  {section.content(section.data, section.value as SectionType)}
                </Section>
              )
            })}
          </div>
        ) : (
          <div className={styles.mobileSections}>
            {/* <Tabs tabs={sections} buttonsMargin={false} /> */}
            <ButtonGroupRadio
              className={styles.group}
              value={activeTab}
              onChange={(_: React.BaseSyntheticEvent, newValue: string) =>
                setActiveTab(Number(newValue))
              }
              exclusive
            >
              {sections.map(({ label, value }, index) => (
                <ButtonGroupRadioButton
                  key={value}
                  value={index}
                  className={cn(styles.button, {
                    [styles.activeTab]: activeTab === index,
                  })}
                >
                  {label}
                </ButtonGroupRadioButton>
              ))}
            </ButtonGroupRadio>

            <Section
              refreshList={() => fetchTokens(1, sections[activeTab].value as SectionType)}
              isLoading={loadingState[sections[activeTab].value as SectionType]}
              title={sections[activeTab].label}
            >
              {sections[activeTab].content(
                sections[activeTab].data,
                sections[activeTab].value as SectionType,
              )}
            </Section>
          </div>
        )}
      </div>
    </div>
  )
}

export { PumpVision }
