import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

// import QRCode from 'qrcode'
import logoText from '@/assets/img/logo/logo-text.svg'
import logo from '@/assets/img/logo/logo.svg'
import { EAuthQueryParams } from '@/components/app/libs/enums/auth-query-params.enum.ts'
import { useAppMode } from '@/hooks/useAppMode.ts'
import { useDebouncedEffect } from '@/hooks/useDebauncedEffect.hook'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config.ts'
import { AppRoute } from '@/libs/enums'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber.ts'
import { PositionDTO } from '@/libs/types'
import { CanvasHandle } from '@/libs/types/canvas.type'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { MAX_CANVAS_WIDTH } from '@/pages/modal-page/libs/components/share-profit/libs/constants'
import { getResponsiveCanvasConfig } from '@/pages/modal-page/libs/components/share-profit/libs/utils/getResponsiveCanvasConfig'
import { drawGradientText } from '@/pages/modal-page/libs/helpers'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  height: number
  width: number
  img: string
  isPositionOpen: boolean
  tokenInfo: TActivePanelItem
  currentChainSymbol: string
  positionData: PositionDTO
}

const Canvas = forwardRef<CanvasHandle, TProps>(
  ({ height, width, img, isPositionOpen, tokenInfo, currentChainSymbol, positionData }, ref) => {
    const { t } = useTranslation()
    const { width: windowWidth } = useWindowDimensions()

    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const isMobile = windowWidth < MAX_CANVAS_WIDTH
    const qrRef = useRef<HTMLDivElement | null>(null)
    useImperativeHandle(ref, () => ({
      getCanvas: () => canvasRef.current,
    }))
    const { mode } = useAppMode()
    const currentChain = useAppSelector((state) => state.chain.currentChain)
    const userData = useAppSelector((state) => state.user.userData)

    const isPositionDTO = (
      positions: typeof tokenInfo.po | PositionDTO,
    ): positions is PositionDTO => {
      return 'worth' in positions && 'sold' in positions && 'cost' in positions
    }

    const calculateTotalProfit = (positions: typeof tokenInfo.po | PositionDTO) => {
      if (isPositionDTO(positions)) {
        const worth = Number(positions.afterTax) || 0
        const soldOut = Number(positions.sold) || 0
        const cost = Number(positions.cost) || 0

        return worth + soldOut - cost
      }

      return positions.reduce((total, position) => {
        const worth = Number(position.at.t) || 0
        const soldOut = Number(position.so.t) || 0
        const cost = Number(position.c.t) || 0

        const positionProfit = worth + soldOut - cost

        return total + positionProfit
      }, 0)
    }

    // const loadImage = (src: string): Promise<HTMLImageElement> =>
    //   new Promise((resolve) => {
    //     const image = new Image()
    //     image.src = src
    //     image.onload = () => resolve(image)
    //   })
    const qrUrl = `${window.location.origin}${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?token=${tokenInfo.ta}&${EAuthQueryParams.AFFILIATE_ID}=${userData?.username}`
    useDebouncedEffect(
      () => {
        const canvas: any = document.getElementById('share-pnl')
        const context = canvasRef.current?.getContext('2d')
        const image = new Image()
        const isSmallDevice = windowWidth < 480
        image.src = img
        image.onload = async () => {
          if (!context || !canvasRef.current || !tokenInfo || !canvas) {
            return
          }

          // Makes canvas sharper
          const devicePixelRatio = window.devicePixelRatio || 1
          canvas.width = width * devicePixelRatio
          canvas.height = height * devicePixelRatio
          canvas.style.width = width + 'px'
          canvas.style.height = height + 'px'
          context.scale(devicePixelRatio, devicePixelRatio)
          const canvasOffsetsConfig = getResponsiveCanvasConfig({
            windowWidth,
            isPositionOpen,
            width,
            height,
          })

          context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
          context.drawImage(image, 0, 0, width, height)
          context.fillStyle = '#ffffff'
          context.font = isMobile ? '600 16px Space Grotesk' : '600 23px Space Grotesk'

          const mobileLogoWidth = Math.min(...[width / 10, 50])

          context?.fillText(
            tokenInfo.tn ? `${tokenInfo.tn} (${tokenInfo.tos})` : tokenInfo.tos,
            canvasOffsetsConfig.tokenNameOffsetX,
            canvasOffsetsConfig.tokenNameOffsetY,
          )

          context.fillStyle = '#BDBDBD'
          context.font = `400 12px Space Grotesk`
          context?.fillText(
            t('token_info.total_spent'),
            canvasOffsetsConfig.tokenNameOffsetX,
            canvasOffsetsConfig.spendTitleOffsetY,
          )
          context.fillStyle = '#ffffff'
          const ethSpentFontSize = isMobile ? (isSmallDevice ? 13 : 15) : 21
          context.font = `500 ${ethSpentFontSize}px Space Grotesk`

          context?.fillText(
            `${Number(
              convertScientificNotationNumber(
                tokenInfo?.tc.t,
                +tokenInfo?.tc.t < 1 ? MAX_TRX_DECIMALS : 2,
              ),
            ).toLocaleString('en-US', {
              maximumFractionDigits: +tokenInfo?.tc.t < 1 ? 5 : 2,
            })} ${currentChainSymbol}`,
            canvasOffsetsConfig.tokenNameOffsetX,
            canvasOffsetsConfig.spendETHValueOffsetY,
          )
          // context.scale(1, 1)
          // context.fillStyle = '#BDBDBD'
          // context.font = `400 ${isSmallDevice ? 12 : 16}px Space Grotesk`
          // context?.fillText(
          //   `$${Number(
          //     convertScientificNotationNumber(tokenInfo?.tc.u, MAX_TRX_DECIMALS),
          //   ).toLocaleString('en-US', { maximumFractionDigits: 5 })}`,
          //   canvasOffsetsConfig.tokenNameOffsetX,
          //   canvasOffsetsConfig.spendUsdValueOffsetY,
          // )

          context.fillStyle = '#BDBDBD'
          context.font = `400 12px Space Grotesk`
          context?.fillText(
            t('token_info.total_profit'),
            canvasOffsetsConfig.revenueTitleOffsetX,
            canvasOffsetsConfig.spendTitleOffsetY,
          )

          context.fillStyle = '#ffffff'
          const usdSpentFontSize = isMobile ? (isSmallDevice ? 13 : 15) : 21
          context.font = `500 ${usdSpentFontSize}px Space Grotesk`
          const isValidPositionData = positionData && 'worth' in positionData
          const totalProfit = calculateTotalProfit(
            isValidPositionData ? positionData : tokenInfo.po,
          )
          const isPositiveProfit = totalProfit > 0
          const profitValue = isPositiveProfit ? '+' : ''
          context?.fillText(
            `${profitValue}${Number(
              convertScientificNotationNumber(totalProfit, totalProfit < 1 ? MAX_TRX_DECIMALS : 2),
            ).toLocaleString('en-US', {
              maximumFractionDigits: totalProfit < 1 ? 5 : 2,
            })} ${currentChainSymbol}`,
            canvasOffsetsConfig.revenueTitleOffsetX,
            canvasOffsetsConfig.spendETHValueOffsetY,
          )
          context.fillStyle = '#BDBDBD'
          context.font = `400 ${isSmallDevice ? 12 : 16}px Space Grotesk`

          // context?.fillText(
          //   `$${Math.abs(
          //     +convertScientificNotationNumber(Number(tokenInfo?.tw.u), MAX_TRX_DECIMALS),
          //   ).toLocaleString('en-US', {
          //     maximumFractionDigits: 5,
          //   })}`,
          //   canvasOffsetsConfig.revenueTitleOffsetX,
          //   canvasOffsetsConfig.spendUsdValueOffsetY,
          // )

          // context.fillStyle = '#ffffff'
          // context.font = '500 14px Space Grotesk'
          // context?.fillText('Realized P/L', 44, isPositionOpen ? 220 : 280)

          const tplFontSize = isMobile ? (isSmallDevice ? 26 : 42) : 62
          context.font = `500 ${tplFontSize}px Space Grotesk`
          const tplValueType = Number(tokenInfo.tpl) > 0 ? '+' : ''

          drawGradientText(
            `${tplValueType}${Number(tokenInfo.tpl).toFixed(2)}`,
            canvasOffsetsConfig.tokenNameOffsetX,
            canvasOffsetsConfig.tplOffsetY,
            context,
          )

          const image2 = new Image()
          image2.src = logo
          image2.onload = () => {
            const newWidthImage2 = isMobile ? mobileLogoWidth : 50
            const newHeightImage2 = image2.height * (newWidthImage2 / image2.width)
            context.drawImage(
              image2,
              canvasOffsetsConfig.tokenNameOffsetX,
              isMobile ? 20 : 50,
              newWidthImage2,
              newHeightImage2,
            )
          }

          const image3 = new Image()
          image3.src = logoText
          image3.onload = () => {
            const newWidthImage3 = isMobile ? width / 4 : 130
            const newHeightImage3 = image3.height * (newWidthImage3 / image3.width)
            context.drawImage(
              image3,
              canvasOffsetsConfig.logoTextOffsetX,
              canvasOffsetsConfig.logoTextOffsetY,
              newWidthImage3,
              newHeightImage3,
            )
          }

          //@ TODO add url
          const imageUrl = () => {
            const qrCanvasNode = qrRef.current?.querySelector('svg')
            if (qrCanvasNode) {
              const svgData = new XMLSerializer().serializeToString(qrCanvasNode)

              const blob = new Blob([svgData], { type: 'image/svg+xml' })

              return URL.createObjectURL(blob)
            }
            return ''
          }
          const qrCodeImage = new Image()
          qrCodeImage.src = imageUrl()
          qrCodeImage.onload = () => {
            qrCodeImage.crossOrigin = 'anonymous'
            qrCodeImage.setAttribute('crossorigin', 'anonymous') // works for me

            const qrPadding = isMobile ? 4 : 6
            const containerPadding = isMobile ? 6 : 12
            const qrSize = isMobile ? (isSmallDevice ? 25 : width / 12) : 60 // size of the QR code
            const qrBlockSize = qrSize + qrPadding * 2 // size of the QR wrapper
            const containerSize = qrBlockSize + containerPadding * 2 // size of the whole container
            const headerWidth = 60

            // Calculate positions for the QR blocks
            const qrOffsetX = isMobile
              ? isSmallDevice
                ? width - 3 * qrSize - 50
                : width - 3 * qrSize - 25
              : width - 220
            const qrOffsetY = isMobile ? width / 2 - qrSize - 10 : height - 110
            const qrBlockX = qrOffsetX + containerPadding
            const qrBlockY = qrOffsetY + containerPadding
            const qrImageX = qrBlockX + qrPadding
            const qrImageY = qrBlockY + qrPadding

            // Draw the black background block with padding
            const containerWidth = containerSize + headerWidth + 2 * containerPadding
            context.beginPath()
            context.fillStyle = 'black'
            context.roundRect(qrOffsetX, qrOffsetY, containerWidth, containerSize, 10)
            context.lineWidth = 1
            context.strokeStyle = '#bdbdbd61'
            context.stroke()
            context.fill()

            // Draw the white background for qr block with padding
            context.fillStyle = 'white'
            context.beginPath()
            context.roundRect(qrBlockX, qrBlockY, qrBlockSize, qrBlockSize, 5)
            context.fill()
            // Draw the QR code image inside the block, centered within the padding
            context.drawImage(qrCodeImage, qrImageX, qrImageY, qrSize, qrSize)

            // Draw headers
            const qrHeaders = ['Trade it.', 'Snipe it.', 'Blaze it.']
            const headerX = qrImageX + qrSize + containerPadding + 5
            const headersGap = qrBlockSize / 3
            const responsiveHeadersGap = isMobile
              ? isSmallDevice
                ? headersGap - 1
                : headersGap
              : headersGap + 1
            for (let index = 0; index < qrHeaders.length; index++) {
              const headerShiftY = isSmallDevice ? 10 : isMobile ? 15 : 5
              const headerY =
                qrBlockY + responsiveHeadersGap * index + containerPadding + headerShiftY
              const headersFontSize = isMobile ? (isSmallDevice ? 10 : 13) : 18
              context.fillStyle = '#fff'
              context.font = `500 ${headersFontSize}px Space Grotesk`
              context?.fillText(qrHeaders[index], headerX, isMobile ? headerY - 5 : headerY)
            }
          }

          const image4 = new Image()
          image4.crossOrigin = 'anonymous'
          image4.setAttribute('crossorigin', 'anonymous') // works for me
          image4.src = tokenInfo.i.s
          image4.onload = () => {
            // context.drawImage(image4, 44, 132, 22, 22)
            context.closePath()
            context.fill()
          }
        }
      },
      [height, img, width, isPositionOpen, tokenInfo],
      200,
    )
    return (
      <>
        <canvas
          id="share-pnl"
          ref={canvasRef}
          width={width}
          height={height}
          className={styles.canvas}
        ></canvas>
        <div ref={qrRef} style={{ display: 'none' }}>
          <QRCode size={120} value={qrUrl} />
        </div>
      </>
    )
  },
)

Canvas.displayName = 'Canvas'

export { Canvas }
