import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import cls from 'classnames'

import { BuySellSnipeProfitSell } from '@/components/buy-sell-snipe-profit-sell'
import { TransactionsContainer } from '@/components/dashboard-component-lite/libs/transactions-container'
import { SmoothScrollOverlay } from '@/components/overlay'
// import { PendingBuySellsTable } from '@/components/pending-buy-sells-table'
// import { PendingDevTransaction } from '@/components/pending-dev-transaction'
import { PendingOrders } from '@/components/pending-orders'
import { SearchWithTokenInfo } from '@/components/search-with-token-info'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Divider, Tabs } from '@/libs/common'
import { Anchor, TabName } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { setIsNavigateAvailable } from '@/store/slices/app.slice'

import { TradingViewNew } from '../trading-view-new'
import styles from './styles.module.scss'

type TProps = {
  width: number
}

const DashboardComponent: FC<TProps> = ({ width }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const [searchParams, setSearchParams] = useSearchParams()
  const anchor = searchParams.get('anchor')
  // const ref = useRef<HTMLInputElement>(null)

  // const [componentWidth] = useComponentWidth(ref)

  const windowDimension = useWindowDimensions()
  const showTabs = windowDimension.width > 1400

  const deleteAnchor = useCallback(() => {
    if (searchParams.has('anchor')) {
      searchParams.delete('anchor')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    dispatch(setIsNavigateAvailable(true))
  }, [])

  useEffect(() => {
    if (!anchor) {
      return
    }
    deleteAnchor()
  }, [anchor, deleteAnchor])

  const tableTabs = useMemo(
    () => [
      {
        label: t('menu.transactions'),
        content: (
          <div className={styles.tabContainer}>
            <TransactionsContainer />
          </div>
        ),
      },
      // {
      //   label: 'Pending Buys/Sells',
      //   content: <PendingBuySellsTable />,
      //   // icon: <LockIcon />,
      // },
      // {
      //   label: 'Holders',
      //   content: <HoldersTable />,
      // },
      // { label: 'History', content: <TokenHistoryTable /> },
    ],
    [selectedToken],
  )

  return (
    <div className={styles.innerContainer}>
      <SearchWithTokenInfo />

      <Grid container wrap="nowrap" className={styles.content}>
        <div className={styles.screenerSection}>
          <TradingViewNew componentWidth={width > 990 ? width + 540 : width - 450} />

          <div className={styles.multiTabTransactionViewer}>
            <Tabs defaultValue={0} tabs={tableTabs} />
          </div>
        </div>

        <Stack className={styles.buySellSection} spacing={2} useFlexGap>
          {showTabs && (
            <>
              <div
                id={Anchor.BUY_SELL}
                className={cls({ [styles.selectedBuySell]: anchor === Anchor.BUY_SELL })}
              >
                <BuySellSnipeProfitSell name={TabName.BUY_SELL} />
              </div>

              <Divider />
            </>
          )}

          <PendingOrders className={styles.buySellSectionItem} />

          <Divider />
          {/* <PendingDevTransaction />

          <Divider /> */}

          {/* <TokenEvents className={styles.buySellSectionItem} /> */}
        </Stack>
      </Grid>
      <SmoothScrollOverlay isOpen={Boolean(anchor)} anchor={anchor} onClick={deleteAnchor} />
    </div>
  )
}

export { DashboardComponent }
