import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TableCell, TableRow } from '@mui/material'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import {
  Button,
  ButtonCopy,
  ButtonIcon,
  Popover,
  type TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TWebhook } from '@/libs/types/webhooks.ts'

import styles from './styles.module.scss'

type TProperty = {
  row: TWebhook
  removeHook: (id: string) => void
  editHook: (row: TWebhook) => void
}

const Row: FC<TProperty> = ({ row, removeHook, editHook }) => {
  const { t } = useTranslation()
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const open = Boolean(deletePopup.anchorE)
  const id = open ? 'delete-popover' : undefined

  return (
    <TableRow className={cn(styles.row)}>
      <TableCell className={cn(styles.cell, styles.nameCell)}>{row?.name}</TableCell>
      <TableCell className={cn(styles.cell, styles.templateCell)}>{row?.template.name}</TableCell>
      <TableCell className={cn(styles.cell, styles.urlCell)}>
        <Stack direction="row" gap={4} alignItems="center" justifyContent={'start'}>
          {row?.url}
          <ButtonCopy value={row?.url} />
        </Stack>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.actionCell)}>
        <div>
          <Button
            styleVariant="borderless-gold"
            onClick={() => editHook(row)}
            iconStartName={IconName.EDIT_SMALL}
          ></Button>
          <Button
            styleVariant="borderless-red"
            iconStartName={IconName.DELETE_RED}
            onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
          />

          <Popover
            id={id}
            className={styles.popover}
            popoverState={deletePopup}
            onChangeVisible={setDeletePopup}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Typography variant="body2" className={styles.title}>
              {t('confirm_delete')}
            </Typography>
            <Typography variant="body2">
              ${t('settings.delete_confirmation')} {row?.name} ${t('settings.webhook')}?
            </Typography>
            <div className={styles.popoverButtons}>
              <ButtonIcon
                onClick={() => {
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                <Typography variant="body2" className={styles.cancel}>
                  {t('cancel')}
                </Typography>
              </ButtonIcon>
              <Button
                className={styles.confirm}
                styleVariant="red"
                onClick={() => {
                  if (row && row.id) removeHook(row.id)
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                {t('delete')}
              </Button>
            </div>
          </Popover>
        </div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
