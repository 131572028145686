import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'
import moment from 'moment'

import { getTokenTransactionsFiltered } from '@/api/tokens'
import { TTokenTransactionFiltered } from '@/api/tokens/types'
import { CustomToast } from '@/components/custom-toast'
import { NoTokenSelected } from '@/components/search-with-token-info/libs/components/no-token-selected'
import { ButtonGroupRadio, ButtonGroupRadioButton, ButtonIcon, Typography } from '@/libs/common'
import { ESorting, IconName } from '@/libs/enums'
import { formatNumberWithCommas } from '@/libs/helper/format-numbers-with-commas'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { roundNumber } from '@/libs/helper/roundNumber'
import { TSelectOption } from '@/libs/types/select-option.type'
import { store, useAppDispatch, useAppSelector } from '@/store'
import { setMyTransactions, setTransactions } from '@/store/slices/chain.slice'

import { Row } from './libs/row'
import styles from './styles.module.scss'

type TColumn = {
  id: string
  label: string | ReactNode
  minWidth?: number
  align?: 'right' | 'left' | 'center'
  format?: (value: string) => string | ReactNode
}

type TProperty = {
  size?: 'basic' | 'small'
  styleValiant?: 'basic' | 'lite'
  ownTransactions?: boolean
}

const TransactionsTable: FC<TProperty> = ({ size, ownTransactions, styleValiant = 'basic' }) => {
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const transactionsData = useAppSelector((state) => state.chain.transactions)
  const myTransactionsData = useAppSelector((state) => state.chain.myTransactions)

  const { t } = useTranslation()

  const dateFormats: TSelectOption<string>[] = [
    {
      value: 'date',
      label: t('token_list.date'),
    },
    {
      value: 'age',
      label: t('token_list.age'),
    },
  ]

  const [dateFormat, setDateFormat] = useState(dateFormats[0].value)
  const [allTx, setAllTx] = useState<TTokenTransactionFiltered[]>(transactionsData?.data || [])
  const [myTx, setMyTx] = useState<TTokenTransactionFiltered[]>(myTransactionsData?.data || [])
  const [cursor, setCursor] = useState(
    (ownTransactions ? myTransactionsData?.cursor : transactionsData?.cursor) || '',
  )
  const [isTransactionLoading, setIsTransactionLoading] = useState(false)
  const [sorting, setSorting] = useState(transactionsData?.sorting || ESorting.DESC)

  const dispatch = useAppDispatch()

  const filterdRows = useMemo(() => {
    const result = ownTransactions
      ? myTx
      : allTx.filter((tx) =>
          tx.event_type.toLowerCase() === 'mint' || tx.event_type.toLowerCase() === 'burn'
            ? !!tx.data
            : true,
        )
    return result || []
  }, [myTx, allTx, ownTransactions, currentToken])

  const sortedRows = useMemo(() => {
    if (sorting === ESorting.DESC) {
      const result = [...filterdRows].sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
      return result
    } else {
      const result = [...filterdRows].sort((a, b) => Number(a.timestamp) - Number(b.timestamp))
      return result
    }
  }, [filterdRows, sorting])

  const handleLastEntry = () => {
    if (ownTransactions) {
      if (myTransactionsData?.lastEntry && myTransactionsData.sorting === ESorting.DESC) {
        setMyTx((prev) => {
          return [
            ...new Map(
              [...(myTransactionsData.lastEntry || []), ...(prev || [])].map((item) => [
                `${item.transaction_hash}_${item.event_type.toLowerCase()}_${item.token_swap_value_usd}_${item.timestamp}`,
                item,
              ]),
            ).values(),
          ]
        })
        dispatch(setMyTransactions({ ...myTransactionsData, lastEntry: undefined }))
      }
    } else {
      if (transactionsData?.lastEntry && transactionsData.sorting === ESorting.DESC) {
        setAllTx((prev) => {
          return [
            ...new Map(
              [...(transactionsData.lastEntry || []), ...(prev || [])].map((item) => [
                `${item.transaction_hash}_${item.event_type.toLowerCase()}_${item.token_swap_value_usd}_${item.timestamp}`,
                item,
              ]),
            ).values(),
          ]
        })
        dispatch(setTransactions({ ...transactionsData, lastEntry: undefined }))
      }
    }
  }

  const fetchData = async (pagination?: string) => {
    if (!selectedTokenAddress || (ownTransactions && !mainWallet)) {
      setAllTx([])
      setMyTx([])
      return
    }

    const currentTxInfo = ownTransactions ? myTransactionsData : transactionsData
    const isSortChanged = currentTxInfo && currentTxInfo.sorting !== sorting
    const isTokenChanged = currentTxInfo?.token && selectedTokenAddress !== currentTxInfo.token

    if (isTokenChanged) {
      setAllTx([])
      setMyTx([])
      dispatch(setTransactions(null))
      dispatch(setMyTransactions(null))
    }

    if (
      !pagination &&
      !isSortChanged &&
      filterdRows?.length &&
      selectedTokenAddress === currentTxInfo?.token
    ) {
      if (currentTxInfo.lastEntry) {
        handleLastEntry()
      }
      return
    }

    setIsTransactionLoading(true)

    if (isSortChanged) {
      ;(ownTransactions ? setMyTx : setAllTx)([])
    }

    try {
      const transactions = await getTokenTransactionsFiltered(
        selectedTokenAddress,
        currentChain.id,
        sorting,
        pagination ? pagination : '',
        ownTransactions && mainWallet ? mainWallet?.address.toLowerCase() : '',
      )

      const filteredData = transactions.items.map((item) => ({
        ...item,
        ...item.data,
        maker: item.ens !== '-' ? item.ens : item.maker,
      }))
      setCursor(transactions.cursor)
      if (ownTransactions) {
        if (!pagination && (!myTransactionsData || isSortChanged || isTokenChanged)) {
          dispatch(
            setMyTransactions({
              sorting,
              data: filteredData,
              token: selectedTokenAddress,
              cursor: transactions.cursor,
            }),
          )
        }
        setMyTx((prev) => {
          const dataToMerge = pagination ? [...(prev || []), ...filteredData] : filteredData

          return [
            ...new Map(
              dataToMerge.map((item) => [
                `${item.transaction_hash}_${item.event_type.toLowerCase()}_${item.token_swap_value_usd}_${item.timestamp}`,
                item,
              ]),
            ).values(),
          ].sort((a, b) => {
            const timeA = Number(a.timestamp)
            const timeB = Number(b.timestamp)
            return sorting === ESorting.DESC ? timeB - timeA : timeA - timeB
          })
        })
      } else {
        if (!pagination && (!transactionsData || isSortChanged || isTokenChanged)) {
          dispatch(
            setTransactions({
              sorting,
              data: filteredData,
              token: selectedTokenAddress,
              cursor: transactions.cursor,
            }),
          )
        }
        setAllTx((prev) => {
          const dataToMerge = pagination ? [...(prev || []), ...filteredData] : filteredData

          return [
            ...new Map(
              dataToMerge.map((item) => [
                `${item.transaction_hash}_${item.event_type.toLowerCase()}_${item.token_swap_value_usd}_${item.timestamp}`,
                item,
              ]),
            ).values(),
          ].sort((a, b) => {
            const timeA = Number(a.timestamp)
            const timeB = Number(b.timestamp)
            return sorting === ESorting.DESC ? timeB - timeA : timeA - timeB
          })
        })
      }
    } catch (error) {
      CustomToast('error', 'orders.error_fetching_tx')
    } finally {
      setIsTransactionLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedTokenAddress, ownTransactions, sorting])

  useEffect(() => {
    handleLastEntry()
  }, [transactionsData?.lastEntry, myTransactionsData?.lastEntry])

  const handleSort = () => {
    setAllTx([])
    setMyTx([])
    setCursor('')
    setSorting((sorting) => {
      return sorting === ESorting.ASC ? ESorting.DESC : ESorting.ASC
    })
  }

  const buttonLink = (transactionHash: string) => (
    <div className={styles.scannerIcon}>
      <ButtonIcon
        icon={(IconName as any)[currentChain.scanLogo]}
        onClick={() =>
          window
            .open(`${store.getState().chain.currentChain.explorer}/tx/${transactionHash}`, '_blank')
            ?.focus()
        }
      />
    </div>
  )

  const columns: TColumn[] = useMemo(
    () => [
      {
        id: 'timestamp',
        label: (
          <div className={styles.timeCell}>
            <ButtonGroupRadio
              className={styles.group}
              value={dateFormat}
              onChange={(_: React.BaseSyntheticEvent, newValue: string) => setDateFormat(newValue)}
              exclusive
            >
              {dateFormats.map(({ value, label }) => (
                <ButtonGroupRadioButton
                  key={value}
                  value={value}
                  className={cn(styles.button, { [styles.active]: dateFormat === value })}
                >
                  {label}
                </ButtonGroupRadioButton>
              ))}
            </ButtonGroupRadio>

            <ButtonIcon
              icon={sorting === ESorting.ASC ? IconName.SORT_ASC : IconName.SORT_DESC}
              onClick={handleSort}
            />
          </div>
        ),
        format: (value) =>
          dateFormat === dateFormats[0].value
            ? moment
                .utc(+value * 1000)
                .zone(new Date().getTimezoneOffset())
                .format('MMM D, HH:mm:ss')
            : moment
                .utc(+value * 1000)
                .startOf('second')
                .fromNow(),
      },
      {
        id: 'event_type',
        label: t('orders.type'),
        align: 'left',
      },
      {
        id: 'token_swap_value_usd',
        label: t('token_list.price') + ' USD',
        align: 'left',
        format: (value) => (value === 'NaN' ? '-' : formatTokenPrice(value).formatted),
      },

      {
        id: 'amount_non_liquidity_token',
        label: currentToken?.token.symbol || '',
        align: 'right',
        format: (value) => (value === undefined ? '-' : formatTokenPrice(value).raw),
      },
      {
        id: 'price_usd_total',
        label: t('orders.total') + ' USD',
        align: 'right',
        format: (value) => (value === 'NaN' ? '-' : formatTokenPrice(value).formatted),
      },
      {
        id: 'price_base_token_total',
        label: `${t('orders.total')} ${currentChain.chainSymbol}`,
        align: 'right',
        format: (value) => (value === 'NaN' ? '-' : formatTokenPrice(value).formatted),
      },

      {
        id: 'maker',
        label: t('token_info.makers'),
        align: 'center',
        format: (value) => (
          <Link
            to={`${store.getState().chain.currentChain.explorer}/address/${value}`}
            target="_blank"
            className={styles.addressLink}
          >
            {hideWalletAddress(value) as string}
          </Link>
        ),
      },
      {
        id: 'transaction_hash',
        label: 'TX',
        align: 'left',
        format: (value) => buttonLink(value),
      },
    ],
    [currentToken, dateFormat, sorting],
  )

  if (!currentToken) {
    return <NoTokenSelected />
  }

  // const VirtualisedRow = ({ index, style }) => {
  //   // return (
  //   //   <div style={style}>
  //   //     <Typography>
  //   //       {sortedRows?.[index]?.timestamp} | {index}
  //   //     </Typography>
  //   //   </div>
  //   // )
  //   const row = sortedRows[index]
  //   const indx = index

  //   if (!row) return null

  //   if (
  //     (row.event_type.toLowerCase() === 'mint' || row.event_type.toLowerCase() === 'burn') &&
  //     row.data
  //   ) {
  //     const secondaryTokenSymbol =
  //       currentToken.token.address !== currentToken.pair.address
  //         ? currentToken.pair.symbol
  //         : currentToken.token.symbol

  //     const secondaryToken = currentToken.info?.quote_token === 'token0' ? 'token1' : 'token0'

  //     const plusOrMinus = row.event_type.toLowerCase() === 'mint' ? '+' : '-'

  //     const primaryTokenPrice =
  //       +row.data[currentToken.info.quote_token] !== 0
  //         ? plusOrMinus +
  //           formatNumberWithCommas(roundNumber(+row.data[currentToken.info.quote_token], 1e5)) +
  //           ` ${currentToken.token.symbol}`
  //         : ''

  //     const secondaryTokenPrice =
  //       +row.data[secondaryToken] !== 0
  //         ? plusOrMinus +
  //           formatNumberWithCommas(roundNumber(+row.data[secondaryToken], 1e5)) +
  //           ` ${secondaryTokenSymbol}`
  //         : ''

  //     return (
  //       <TableRow
  //         role="checkbox"
  //         tabIndex={-1}
  //         key={row.transaction_hash + indx}
  //         className={cn(styles.bodyRow, styleValiant === 'basic' ? styles.basic : styles.lite)}
  //         style={style}
  //       >
  //         <TableCell className={cn(styles.cell, styles.timestamp)}>
  //           {dateFormat === dateFormats[0].value
  //             ? moment
  //                 .utc(+row.timestamp * 1000)
  //                 .zone(new Date().getTimezoneOffset())
  //                 .format('MMM D, HH:mm:ss')
  //             : moment
  //                 .utc(+row.timestamp * 1000)
  //                 .startOf('second')
  //                 .fromNow()}
  //         </TableCell>
  //         <TableCell
  //           className={cn(styles.cell, {
  //             [styles.mint]: row.event_type.toLowerCase() === 'mint',
  //             [styles.burn]: row.event_type.toLowerCase() === 'burn',
  //           })}
  //         >
  //           {row.event_type.toLowerCase() === 'mint' ? 'Added Liquidity' : 'Removed Liquidity'}
  //         </TableCell>
  //         <TableCell
  //           colSpan={columns.length - 4}
  //           className={cn(styles.cell, {
  //             [styles.mint]: row.event_type.toLowerCase() === 'mint',
  //             [styles.burn]: row.event_type.toLowerCase() === 'burn',
  //           })}
  //         >
  //           {secondaryTokenPrice && !primaryTokenPrice && `${secondaryTokenPrice}`}
  //           {primaryTokenPrice && !secondaryTokenPrice && `${primaryTokenPrice}`}
  //           {primaryTokenPrice &&
  //             secondaryTokenPrice &&
  //             `${primaryTokenPrice} and ${secondaryTokenPrice}`}
  //         </TableCell>

  //         <TableCell
  //           align="center"
  //           className={cn(styles.cell, {
  //             [styles.mint]: row.event_type.toLowerCase() === 'mint',
  //             [styles.burn]: row.event_type.toLowerCase() === 'burn',
  //           })}
  //         >
  //           <Link
  //             to={`${store.getState().chain.currentChain.explorer}/address/${row.maker}`}
  //             target="_blank"
  //             className={styles.addressLink}
  //           >
  //             {hideWalletAddress(row.maker)}
  //           </Link>
  //         </TableCell>

  //         <TableCell align="left" className={cn(styles.cell)}>
  //           {buttonLink(row.transaction_hash)}
  //         </TableCell>
  //       </TableRow>
  //     )
  //   }

  //   return (
  //     <Row
  //       columns={columns}
  //       row={row}
  //       key={row.transaction_hash + indx}
  //       styleValiant={styleValiant}
  //       style={style}
  //     />
  //   )
  // }

  // const itemCount = !!cursor ? sortedRows.length + 1 : sortedRows.length
  // const isItemLoaded = (index: number) => !!sortedRows[index]

  return (
    <div className={cn(styles.root)}>
      {/* TODO: Uncomment to enable virtualisation after its fix */}
      {/* <AutoSizer disableHeight style={{ width: '100%' }}>
        {({ width }) => (
          <TableContainer
            className={cn(
              styles.container,
              { [styles.containerSmall]: size === 'small' },
              { [styles.noData]: !filterdRows?.length && !isTransactionLoading },
            )}
            id="transactions-table"
          >
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={() => fetchData(cursor)}
            >
              {({ onItemsRendered, ref }) => (
                <>
                  <Table aria-label="sticky table" classes={styles}>
                    <TableHead className={styles.head}>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={cn(
                              styles.tableHeadCell,
                              styleValiant === 'basic' ? styles.basic : styles.lite,
                              {
                                [styles.time]: column.id === 'timestamp',
                              },
                            )}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className={styles.tableBody}>
                      <FixedSizeList
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        height={500}
                        itemSize={40}
                        width={width}
                        outerElementType="tr"
                        innerElementType="td"
                        className={styles.virtualisedList}
                      >
                        {VirtualisedRow}
                      </FixedSizeList>
                    </TableBody>
                  </Table>
                </>
              )}
            </InfiniteLoader>
          </TableContainer>
        )}
      </AutoSizer> */}
      {/* {sortedRows?.map((row, index) => <VirtualisedRow index={index} style={{}} />)} */}

      <InfiniteScroll
        dataLength={filterdRows?.length || 0}
        next={() => fetchData(cursor)}
        hasMore={!!cursor} // to avoid looping
        scrollableTarget="transactions-table"
        loader={
          isTransactionLoading && (
            <Typography
              variant="body1"
              align="center"
              className={cn(
                styles.background,
                styles.loader,
                { [styles.noData]: !filterdRows?.length },
                styleValiant === 'basic' ? styles.basic : styles.lite,
              )}
            >
              {t('loading')}...
            </Typography>
          )
        }
      >
        <TableContainer
          className={cn(
            styles.container,
            { [styles.containerSmall]: size === 'small' },
            { [styles.noData]: !filterdRows?.length && !isTransactionLoading },
          )}
          // style={{ maxHeight: `${height}px` }}
          id="transactions-table"
        >
          <Table aria-label="sticky table" classes={styles}>
            <TableHead className={styles.head}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={cn(
                      styles.tableHeadCell,
                      styleValiant === 'basic' ? styles.basic : styles.lite,
                      {
                        [styles.time]: column.id === 'timestamp',
                      },
                    )}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={styles.tableBody}>
              {sortedRows?.map((row, indx) => {
                if (
                  (row.event_type.toLowerCase() === 'mint' ||
                    row.event_type.toLowerCase() === 'burn') &&
                  row.data
                ) {
                  const secondaryTokenSymbol =
                    currentToken.token.address !== currentToken.pair.address
                      ? currentToken.pair.symbol
                      : currentToken.token.symbol

                  const secondaryToken =
                    currentToken.info?.quote_token === 'token0' ? 'token1' : 'token0'

                  const plusOrMinus = row.event_type.toLowerCase() === 'mint' ? '+' : '-'

                  const primaryTokenPrice =
                    +row.data[currentToken.info.quote_token as keyof typeof row.data] !== 0
                      ? plusOrMinus +
                        formatNumberWithCommas(
                          roundNumber(
                            +row.data[currentToken.info.quote_token as keyof typeof row.data],
                            1e5,
                          ),
                        ) +
                        ` ${currentToken.token.symbol}`
                      : ''

                  const secondaryTokenPrice =
                    +row.data[secondaryToken] !== 0
                      ? plusOrMinus +
                        formatNumberWithCommas(roundNumber(+row.data[secondaryToken], 1e5)) +
                        ` ${secondaryTokenSymbol}`
                      : ''

                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.transaction_hash + indx}
                      className={cn(
                        styles.bodyRow,
                        styleValiant === 'basic' ? styles.basic : styles.lite,
                      )}
                    >
                      <TableCell className={cn(styles.cell, styles.timestamp)}>
                        {dateFormat === dateFormats[0].value
                          ? moment
                              .utc(+row.timestamp * 1000)
                              .zone(new Date().getTimezoneOffset())
                              .format('MMM D, HH:mm:ss')
                          : moment
                              .utc(+row.timestamp * 1000)
                              .startOf('second')
                              .fromNow()}
                      </TableCell>
                      <TableCell
                        className={cn(styles.cell, {
                          [styles.mint]: row.event_type.toLowerCase() === 'mint',
                          [styles.burn]: row.event_type.toLowerCase() === 'burn',
                        })}
                      >
                        {row.event_type.toLowerCase() === 'mint'
                          ? t('token_info.added_liquidity')
                          : t('token_info.removed_liquidity')}
                      </TableCell>
                      <TableCell
                        colSpan={columns.length - 4}
                        className={cn(styles.cell, {
                          [styles.mint]: row.event_type.toLowerCase() === 'mint',
                          [styles.burn]: row.event_type.toLowerCase() === 'burn',
                        })}
                      >
                        {secondaryTokenPrice && !primaryTokenPrice && `${secondaryTokenPrice}`}
                        {primaryTokenPrice && !secondaryTokenPrice && `${primaryTokenPrice}`}
                        {primaryTokenPrice &&
                          secondaryTokenPrice &&
                          `${primaryTokenPrice} ${t('orders.and')} ${secondaryTokenPrice}`}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={cn(styles.cell, {
                          [styles.mint]: row.event_type.toLowerCase() === 'mint',
                          [styles.burn]: row.event_type.toLowerCase() === 'burn',
                        })}
                      >
                        <Link
                          to={`${store.getState().chain.currentChain.explorer}/address/${row.maker}`}
                          target="_blank"
                          className={styles.addressLink}
                        >
                          {hideWalletAddress(row.maker)}
                        </Link>
                      </TableCell>

                      <TableCell align="left" className={cn(styles.cell)}>
                        {buttonLink(row.transaction_hash)}
                      </TableCell>
                    </TableRow>
                  )
                }
                if (
                  row.event_type.toLowerCase() === 'burn' ||
                  row.event_type.toLowerCase() === 'mint'
                ) {
                  return null
                }
                return (
                  <Row
                    columns={columns}
                    row={row}
                    key={row.transaction_hash + indx}
                    styleValiant={styleValiant}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>

      {!filterdRows?.length && !isTransactionLoading && (
        <div className={styles.emptyState}>
          <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
            {t('token_info.no_transactions')}
          </Typography>
        </div>
      )}
    </div>
  )
}

export { TransactionsTable }
export type { TColumn }
